import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../Layout'
import SEO from '../../SEO'

import PaperPrint from '../../components/PaperPrint'

export const query = graphql`
  {
    file(relativePath: { eq: "print/home-serigraphy.jpg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const PaperPrintPage = ({ data }) => (
  <Layout withPadding={false}>
    <SEO
      title="Impression Papier"
      keywords={[
        'papier',
        'paper',
        'print',
        'impression papier',
        'commande',
        'serigraphie',
      ]}
      image={data.file.childImageSharp.resize}
    />
    <PaperPrint />
  </Layout>
)

PaperPrintPage.propTypes = {
  data: shape({}).isRequired,
}

export default PaperPrintPage
