/* eslint-disable max-len */
export const description = [
  'Sérigraphie artisanale depuis 2010, toutes nos impressions sont réalisées manuellement et avec le plus grand soin. Nous utilisons des encres à base d’eau et vegan, pour un plus grand respect de l’environnement et de meilleures conditions de travail et tous nos textiles sont certifiés par la norme Oeko-Tex 100. Dans ce sens, nous vous encourageons à choisir du textile en coton biologique et équitable pour vos commandes.',
  'N’hésitez pas à nous contacter par e-mail pour toute question, si vous souhaitez imprimer sur un autre support que ceux proposés et pour commander ! Envoyez votre demande à prints@oldtree.fr',
]

export const subTitle = 'Pour le détail voir la grille tarifaire à la fin de la page'

export const biColor = 1
export const biColorSpped = 2

export const classique = [
  {
    id: '20-49',
    min: 20,
    max: 49,
    price: 7.00,
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: 6.50,
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: 6.00,
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: 5.50,
  },
]

export const fairtrade = [
  {
    id: '20-49',
    min: 20,
    max: 49,
    price: 8.00,
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: 7.50,
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: 7.00,
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: 6.50,
  },
]

export const classiqueSpped = [
  {
    id: '20-49',
    min: 20,
    max: 49,
    price: { unity: 7.00, forfait: 50 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 6.50, forfait: 50 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: 6.50,
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: 6.00,
  },
]

export const fairtradeSpped = [
  {
    id: '20-49',
    min: 20,
    max: 49,
    price: { unity: 8.00, forfait: 50 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 7.50, forfait: 50 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: 7.50,
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: 7.00,
  },
]
