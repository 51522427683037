import styled from 'styled-components'

export const PapersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 350px;
  margin: 15px auto 0;
`

export const PaperWrapper = styled.div`
  width: 110px;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: ${({ isSelected, theme: { color } }) => isSelected
    ? `1px solid ${color.secondary.main}`
    : 'none'
};
`

export const PaperTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PaperTitle = styled.span`
  font-size: ${({ theme: { size } }) => size.fontSize.xxl};
  color: ${({ theme: { color } }) => color.text_negative};
`

export const PaperSubtitle = styled.span`
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  color: ${({ theme: { color } }) => color.text_negative};
  text-align: center;
  position: relative;
  top: 4px;
`

export const Paper = styled.div`
  width: 100px;
  height: 145px;
  background-color: ${({ theme: { color } }) => color.text};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const TablesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
