/* eslint-disable react/no-array-index-key */
import React from 'react'
import { string, arrayOf } from 'prop-types'

import {
  Table, THead, TH, TBody, Tr, Td,
} from './styles'

const TshirtTable = ({ title, lines }) => (
  <Table>
    {title && (
      <THead>
        <Tr>
          <TH>{title}</TH>
        </Tr>
      </THead>
    )}
    <TBody>
      {
        lines.map((line, i) => (
          <Tr key={i}>
            {
              line.map((str, i) => (
                <Td key={i}>{str}</Td>
              ))
            }
          </Tr>
        ))
      }
    </TBody>
  </Table>
)

TshirtTable.defaultProps = {
  title: null,
}

TshirtTable.propTypes = {
  title: string,
  lines: arrayOf(arrayOf(string)).isRequired,
}

export default TshirtTable
