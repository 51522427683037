import styled, { css } from 'styled-components'

export const TablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`

export const Table = styled.table`
  border: 1px solid ${({ theme: { color } }) => color.secondary.main};
  width: 270px;
`

export const THead = styled.thead`
`

export const TH = styled.th.attrs(() => ({
  colSpan: 2,
}))`
  padding: 10px 15px;
  width: 100%;
  border-bottom: 1px solid ${({ theme: { color } }) => color.secondary.main};
  color: ${({ theme: { color } }) => color.secondary.main};
`

export const TBody = styled.tbody``

export const Tr = styled.tr`
  border-bottom: 1px solid ${({ theme: { color } }) => color.secondary.main};
`

export const Td = styled.td`
  white-space: nowrap;
  padding: 10px 15px;
  &:nth-child(2) {
    text-align: right;
  }
`

const isHeader = ({ $header }) => $header
  ? css`
    color: ${({ theme: { color } }) => color.secondary.main};
    text-align: center;
    border-bottom: none;
  `
  : css`
    border-top: none;
  `

export const SingleLine = styled.div`
  border: 1px solid ${({ theme: { color } }) => color.secondary.main};
  min-height: 25px;
  width: 270px;
  padding: 10px;
  ${isHeader}

  @media(min-width: 570px) {
    width: ${({ twoLines }) => twoLines ? '540px' : '270px'};
  }
`
