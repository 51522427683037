import styled from 'styled-components'

import { Description as D, DevisTitle } from '../Cabs/styles'
import InputBase from '../Input'
import { ClassicLink } from '../StyledLink'

import pdfFile from '../../images/specification-technique-2.pdf'

export const Description = styled(D)`
  padding-bottom: 15px;
`

export const Subtitle = styled(DevisTitle)`
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  margin-top: 0;
  margin-bottom: 20px;
  color: ${({ theme: { color } }) => color.secondary.dark};
`

export const InputLabel = styled.div`
  color: ${({ error, theme: { color } }) => error
    ? color.red
    : color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  padding: 0px 0px 10px 5px;
`

export const Input = styled(InputBase)`
  background-color: transparent;
  border: 1px solid ${({ error, theme: { color } }) => error
    ? color.red
    : color.secondary.main};
  color: ${({ error, theme: { color } }) => error
    ? color.red
    : color.secondary.main};
  width: 100%;
  padding: 6px 16px;
  height: 40px;

  &:focus {
    border: 1px solid ${({ error, theme: { color } }) => error
    ? color.red
    : color.secondary.light}
  }
`

export const InputErrorMessage = styled(InputLabel)`
  min-height: 30px;
  font-size: ${({ theme: { size } }) => size.fontSize.xs};
  padding-top: 4px;
`

export const InputContainer = styled.div`
  margin: 0 auto;
  max-width: 330px;
`

export const DonwloadPDF = styled(ClassicLink).attrs(() => ({
  href: pdfFile,
  target: '_blank',
}))`
  color: ${({ theme: { color } }) => color.secondary.main};
  text-decoration: underline;
`
