/* eslint-disable max-len */
import React from 'react'

import TshirtTable from '../Tshirt/Grille/TshirtTable'
import { TablesContainer } from '../Tshirt/Grille/styles'
import { TablesWrapper } from './styles'

import { a3Table, a2Table } from './data'
import { genPaperTable } from './helpers'

const Grille = () => (
  <TablesWrapper>
    <TablesContainer>
      <TshirtTable
        title="jusqu'à A3"
        lines={genPaperTable(a3Table)}
      />
    </TablesContainer>
    <TablesContainer>
      <TshirtTable
        title="jusqu'à A2"
        lines={genPaperTable(a2Table)}
      />
    </TablesContainer>
  </TablesWrapper>
)

export default Grille
